<template>
  <div>
    <v-card>
      <v-card-title> 404 Not Found </v-card-title>
      <v-card-subtitle>Oops...</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
